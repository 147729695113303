<template>
  <v-card elevation="0" class="bar-status pa-0 my-4" :class="barClass">
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col cols="6">
          <div class="py-2 pl-3">
            <slot name="label"/>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="py-2 pr-3 text-right">
            <slot name="value"/>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import mixins from "./mixins"
export default {
  mixins: [mixins],
}
</script>
